.about-container {
  max-width: 920px;
  margin: auto;
  padding: 24px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  background-color: #fff;
}



.about-title {
  height: 30px;
  align-self: stretch;
  flex-grow: 0;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #006fcf;
  padding: 30px 0;

  & p {
    width: 80%;
    margin: auto;
    text-align: center;
  }
}

.about-text {
  flex-grow: 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #53565a;
}

h3 {
  text-align: center;
}

.riskline-container {

  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  background-color: #f0f8ff;
  border-radius: 1rem 1rem 0 0;
}



.riskline-title {
  padding-top: 46px;
  padding-bottom: 30px;
  height: 30px;
  align-self: stretch;
  flex-grow: 0;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #006fcf;
}

.riskline-text {
  flex-grow: 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #53565a;
}

@media screen and (max-width: 1500px) {
  .riskline-text {
    margin-right: 0px;
  }
}

.riskline-text .link-style {
  color: #006fcf;
}

@media only screen and (max-width: 1320px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .further_resources_container {
    justify-content: space-between;

    .tai {
      margin-right: 2rem !important;
    }
  }
}
