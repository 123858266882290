.search {
    &-title {
        color:#006fcf;
        padding: 15px;
        font-size:20px;
        text-align: center;
    }
    
    &-content{
        color: #505256;
        font-size: 16px;
        text-align: center;

        & a{
            text-decoration: none;
        }
    }
}



.about-section a{
    text-decoration: none;
}

.traveler-search {
    &-title {
        height: 40px;
        background-color: #00175a;
        color: white;
        padding: 6px 20px;
    }

    &-section {
        height: auto;
        background-color:#006fcf;
        color: white;
        padding: 25px 20px;
    }

    &-icon{
        width: 17px;
        height: 17px;
        transform: rotate(-25deg);
    }
}

.add-button{
    color: white !important;
    border-color: white !important; 
    margin-top: 15px;
}

.selected {
    &-list {
        border: 1px solid #C8C9C7;
        padding: 10px;
        margin: 5px;
        display: inline-block;
    }
    
    &-item-close {
        color:#006fcf;
        float: right;
    }
    
    &-item-title{
        color:#006fcf;
        font-weight:500;
        float: left;
        margin-right: 10px;
    }

    &-item-code {
        font-size: 14px;
        clear: both;
    }
}

.suggestion{
    &-list {
        max-height: 11rem;
        height:auto;
        overflow-y: auto;
        left: 0%;
        position: absolute;
        top: 41px;
        background-color: white;
        color: #006fcf;
        padding-left: 0px;
        width: 100%;
        z-index: 10;
        border: 1px solid #C8C9C7;
        list-style: none;

        &-item {
            padding: 5px 10px;
            
            & a {
                text-decoration: none !important;
                color: #007bff !important;            
            }

            &:hover{
                background-color:#d3d3d345;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .add-button{
        width: 100% !important;
    }
    .selected {
        &-list {
            display: block;
        }
        &-item-title{
            width: 17rem;
            margin-right: 0px;
        }
    }
    ::-webkit-input-placeholder { 
        white-space:pre-line;  
        position:relative;
        top:-9px;
        font-size: 0.75rem;
    }
    .entity-type-addintional-details{
        padding: 15px;
    }
}


