.pill-clear {
  display: flex;
  border-radius: 51px;
  border: solid 1px dark;
  margin-right: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  background-color: #fff;
  flex-basis: auto;
  flex-wrap: wrap;
}

.pill-clear-title {
  flex-grow: 1;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #006fcf;
}
.pill-clear-text a {
  text-decoration: none;
}

@media only screen and (max-width: 992px) {
  .pill {
    margin-top: 10px;
  }
}
