.container {
    padding: 2rem 0;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}

.indicator {
    height: .6rem;
    width: .6rem;
    border-radius: 50%;
    background: #fff;

    &__item {
        display: flex;
        align-items: center;
        position: relative;
    }
    
    &__box {
        width: 1rem;
        height: 1rem;
        display: flex;
        align-items: center;
        background: #fff;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
    }
}

.seperator {
    width: 3rem;
    height: .1rem;
    margin: 0 .2rem;
    background: #fff;
}

.label {
    position: absolute;
    top: 1rem;
    transform: translateX(-30%);
    cursor: pointer;
    font-size: .9rem;
}

.link:hover,
.link:visited,
.link:hover {
    text-decoration: none;
    color: #fff;
}

@media screen and (max-width: 768px) {
    .seperator {
        width: 2.5rem;
    }
}

@media screen and (max-width: 480px) {
    .seperator {
        width: 2rem;
    }
}