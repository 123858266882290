.containerStep {
  display: flex;
  padding-top: 40px;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

.wrapper {
  display: flex;
  gap: 5px;
  max-width: 160px;
  align-items: center;
  justify-content: center;
}

progress {
  height: 4px;
  border-radius: 10px;
  border: solid 3px #ecedee;
}

.filled {
  border: solid 3px #006fcf;
}

.ellipse {
  display: flex;
  justify-content: center;
}

.ellipse img {
  flex: 1;
  height: 24px;
  width: 24px;
}

.searchText {
  height: 22px;
  flex-grow: 1;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #006fcf;
}

.wrapper2 {
  display: flex;
  gap: 5px;
  max-width: 180px;
  align-items: center;
  justify-content: center;
}

.separator {
  height: 4px;
  border-radius: 10px;
  border: solid 4px #fff;
  width: 150px;
}
