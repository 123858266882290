.accordion {
    &__container {
        border: 1px solid #414141;
    }  

    &__card_header {
        padding: .7rem;

        & > div {
            display: flex;
            align-items: center;
            cursor: pointer;
            
            & > * {
                margin: 0 .4rem;
            }
        }
    }
      
    &_item {
        &_header {
            display: flex;
    
            & > * {
                margin: 0 .4rem;
            }
        } 
        
        &_container {
            padding: .8rem;
            border: 1px solid #414141;
            border-collapse: collapse;
        }
    }

    &__details__container {
        /*transition: max-height .5s;*/
        overflow: hidden;
        transition: all .4s;
        
        & > * {
          display: flex;
          align-items: baseline;
        }
    }      
     
    &__toggler {
        height: 1.2rem; 
        margin-right: .4rem; 
        transition: transform 1s                    
    }
}

h4{
    font-weight: 800;
}
@media screen and (max-width: 768px){
.accordion__details__container>* {
    flex-wrap: wrap!important;
    width: 100% !important;
}}
@media only screen and (min-width: 768px) {
    .accordion__details__container >  * {
        & > h4 {
            min-width: 20%;
            max-width: 20%;
        }

        & > *:last-child {
            margin-bottom: 0.5rem !important;
        }
    }

}
.accordion__details__container > div> label{
   max-width: -webkit-fill-available;
}
.accordion__details__container>*>:last-child {
    margin-bottom: .5rem!important;
}