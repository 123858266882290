.header-1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  padding: 32px 42px;
}

.active {
  color: #006fcf;
  text-decoration: none;
  padding-bottom: 7px;
  border-bottom: solid 2px #006fcf;
}

.about {
  text-decoration: none;
}

.title {
  flex-grow: 1;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #00175a;
}

.title_mobile {
  flex-grow: 1;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #00175a;
  margin-left: 90px;
}

.google_icon {
  display: block;
  width: 30px;
  position: absolute;
  padding: 5px;
}

.menu {
  margin: 0;
  list-style: none;
}

.right-section {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.item-link {
  text-decoration: none;
  margin-right: 40px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #00175a;
}
.item-link:hover {
  text-decoration: none;
}

select {
  font-size: large;
  color: #4a4a4a;
}

/*.menu_icon {
  display: none;
}*/

.lang-select {
  display: block;
  gap: 12px;
  padding: 8px 12px;
  height: 40px;
  border-radius: 6px;
  width: 220px;
  border: solid 1px #ecedee;
  background: url("https://cdn.cdnlogo.com/logos/g/23/goolge-icon.png")
    no-repeat 10px center;
  padding-left: 40px;
  background-size: 25px;
}

.title_mobile {
  display: none;
  margin-left: 25px;
}

/* Extra small devices (phones, 600px and down) */
/*@media only screen and (max-width: 800px) {
  nav {
    padding: 12px 15px;
  }
  .lang-select {
    display: none;
  }
  .item-link {
    display: none;
  }
  .menu_icon {
    display: block;
  }
  .header-section {
    padding: 0% 20% 0% 10% !important;
  }
  .box {
    flex-grow: 1;
  }
  .mobile_container_dropdown {
    display: block;
  }
}*/

/* Small devices (portrait tablets and large phones, 600px and up) */

/* Medium devices (landscape tablets, 768px and up) */
/*@media only screen and (min-width: 768px) {
  .mobile_container_dropdown {
    display: none;
  }
}*/

/* Large devices (laptops/desktops, 992px and up) */
/*@media only screen and (max-width: 1080px) {
  .title_mobile {
    display: block;
    margin-left: 25px;
  }
  .title {
    display: none;
  }
}*/

@media only screen and (min-width: 768px) {
  .decommStyle {
    font-size : 20px;
  }
}
@media only screen and (max-width: 767px) {
  .decommStyle {
    font-size : 15px;
  }
}

.input-wrapper {
  display: inline-block;
  position: relative;
}

.input-wrapper:after {
  content: "\f274";
  position: absolute;
  left: 6px;
}
.header-section {
  display: flex;
  height: 130px;
  padding: 0 18%;
  align-items: flex-end;
  background-image: url("./1x\ Amex\ GBT\ Pattern\ bg.jpg");
  justify-content: center;
  background-size : cover;
  position: relative;
  border-radius: 15px 15px 0 0;
}
.header-section::before {
  content: "";
  position: absolute;
  
  background-color: transparent;
  bottom: -153px;
  height: 153px;
  width: 15px;
  border-top-left-radius: 25px;
  box-shadow: 0 -25px 0 0 #00175a;
  left: 0px
}
.header-section::after {
  content: "";
  position: absolute;
  
  background-color: transparent;
  bottom: -153px;
  height: 153px;
  width: 15px;
  border-top-right-radius: 25px;
  box-shadow: 0 -25px 0 0 #00175a;
  right: 0px
}
@media screen and (max-width: 540px){
.header-section::before,
.header-section::after{
height : 213px;
}
}
/* @media only screen and (max-width: 1250px) {
  .header-section{
    padding: 0 2%;
  }
} */
.search-div :hover .icon-change{
  content: url("./serch_icon_filled.svg");
  
  }
  .map-div :hover .icon-change{
    content: url("./map_icon_filled.svg");
  }
  @media only screen and (max-width: 540px) {
    .search-div {
      flex-grow: 1;
    }
    .map-div{
      flex-grow: 1;
    }
    .header-section{
      padding: 0%;
    }
  }
.search-div{
  height: 60px;
  width: 160px;
}
.map-div {
  height: 60px;
  width: 160px;
}
.box {
  display: flex;
  height: 60px;
  width: auto;
  padding: 19px 20px;
  background: #f7f8f9;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #53565a;
  cursor: pointer;
}
.box .span {
  margin-left: 10px;
}
.box:hover {
  text-decoration: none;
}
.box input {
  border: none;
  width: 100%;
  text-align: center;
  gap: 12px;
  background: #f7f8f9;
}

.box input:focus,
input:focus {
  outline: none;
}

.box input::placeholder {
  background: url("./serch_icon.svg") no-repeat 10px;
}

.box-active {
  background-color: #fff !important;
  color: #006fcf;
  height: 40px;
  border-bottom: solid 4px #006fcf;
}

.about-div {
  padding: 22px 22px 22px 16px;
}
.about {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #53565a;
}

.select-div {
  padding: 22px 22px 22px 16px;
}

.select {
  display: block;
  gap: 12px;
  height: 40px;
  border-radius: 6px;
  width: 100%;
  border: solid 1px #ecedee;
  background: url("https://cdn.cdnlogo.com/logos/g/23/goolge-icon.png")
    no-repeat 10px center;
  padding-left: 40px;
  background-size: 25px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #53565a;
  background-color: #fff !important;
}

.mobile_container_dropdown {
  position: absolute;
  background-color: #f7f8f9;
  width: 100%;
}

.header-1 {
  display: flex;
  align-items: center;
  height: 6rem;
  padding: 2rem;
}

@media only screen and (min-width: 1048px) {
  .header-1 div:nth-child(6),
  .header-1 div:nth-child(3) {
    display: none;
  }
}
@media only screen and (max-width: 1047px) {
  .header-1 div:nth-child(5),
  .header-1 div:nth-child(4),
  .header-1 div:nth-child(3) {
    display: none;
  }

  @media only screen and (max-width: 740px) {
    .header-1 {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      height: 56px;
      background-color: #fff;
      padding: 13px;
    }
    .header-1 div img {
      height: 18px;
      width: 18px;
    }
    .header-1 div a img {
      height: 24px;
      width: 75px;
    }
    
    .title_mobile {
      font-size: 10px;
      text-align: center;
    }
    .header-1 div:nth-child(3) {
      display: block;
      justify-content: center;
    }
    .header-1 div:nth-child(2) {
      display: none;
    }
  }
}
