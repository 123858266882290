/*header{
    padding: 30px 10px;
    height:50%;
    color:white;
    background-image: url("/images/1x World Service Pattern.png");
}*/

a.nav-link {
    font-size: 13px;
    color: white !important;
}

#navbarNav{
    flex-direction: row-reverse;
}

.header {

    &-logo-image {
        height: 63px;
        width: 141px;
    }
    
    &-title {
        justify-content: center;
        align-items: center;
    }

    &-title1 {
        font-weight: 600;
        font-size:35px;
    }
    
    &-title2 {
        font-weight: 400;
        font-size:19px;
    }
}

.no-margin{
    margin: 0px;
}

@media screen and (min-width: 1100px){
    header .header-logo-image {
        height: 80px;
        width: 180px;
    }
}

.info_and_advisory__container > div.entity-type-addintional-details > p:nth-child(1) > span{
   font-weight: 800;
}