h1, h4,h5 {
    @font-face {
      font-family: 'BentonSans Bold';
      src: url('/Assets/Assets/fonts/bentonsansbold/BentonSans Bold.otf');
    }
  }
  
  h3 {
    font-size: 30px;
    color: #006FCF !important;
  }
  
  h5 {
    font-size: 16px;
  }
  
  h4 {
    font-size: 16px;
  }
  
  a {
    color: #006FCF;
    font-size: 16px;
  }
  
  .align-center {
    text-align: center;
  }
  
  .main-blue-text {
    color: #006FCF;
  }
  
  .white-text {
    color: #fff;
  }
  
  .uppercase {
    text-transform: uppercase;
  }
  
  .bold {
    font-weight: 700;
  }
  
  .inline-block {
    display: inline-block;
  }
  
  .margin-auto {
    margin: 0 auto;
  }
  
  .mb-tiny {
    margin-bottom: .5rem;
  }
  
  .mb-small {
    margin-bottom: 1rem;
  }
  .mb-big {
    margin-bottom: 1.5rem;
  }
  .mb-huge {
    margin-bottom: 2rem;
  }
  
  .heading__title {
    font-size: 20px;
    color: #006fcf;
    padding: 1rem 0;
    
  }

  .nav-link {
    margin: 0;
    padding: 0;
  }

  .as_link,
  .opearations_button > span {
    font-size: 16px;
    color: #006FCF;
    cursor: pointer;
  }

  .bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before{
    border-left-color: #006FCF;
  }

  .tooltip-inner{
    background-color: #006FCF;
  }

  .fakeText {
    margin: 0 auto;
    padding: 0rem;
  }
  

  @media screen and (max-width: 768px) {
    .flex__row {
      flex-direction: column;
      text-align: center
      
    }
  
    .flex__row > div {
      margin: 1rem 0;
    }
  }